body {
    font-family: "open sans",sans-serif;
    font-size:26px;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#de8d69;
    text-transform:uppercase;
}

img.display-left {
    float:left;
    margin-right:10px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    margin-left:10px;
    margin-bottom:10px;
}

aside {
    float:right;
    width:38%;
    box-sizing:border-box;
}
aside p {
    margin-top:0;
}
aside h1 {
    display:inline-block;
    border-bottom: 1px solid #ff7f2a;
    font-family: "halyard-micro",serif;
    font-size:80px;
}
h1,h2,h3 {
    font-weight:normal;
    text-transform:uppercase;
    font-family: "proxima-nova",sans-serif;
}
/* mainly for project page, but applies to all galleries */
kmsgallery,
.kmsgallery {
	max-width: 60%;
}
/* Also a project page special... */
aside td {
    border-bottom:1px solid black;
    padding:10px;
}
aside tr:last-child td {
    border-bottom:none;
}
@media only screen and (max-width: 960px) {
    .kmsgallery {
        max-width:100%;
    }
    aside {
        float:none;
        width:auto;
    }
}